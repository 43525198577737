import "./deck.scss";
import { renderPlaceholder } from './helperFunctions';
import { Card } from '../card/Card';
import { CardType } from '../../utils/cardType';
import { CardStack } from '../../utils/cardStack';
import { useState, useEffect } from 'react';

export const Deck = (props) => {    
   
    const [cards, setCards] = useState<CardType[]>([]);
    const [showBackcard, setShowBackcard] = useState(true);


    useEffect(() => {
        const cardStack = new CardStack(); // create Class
        cardStack.setLevelOfDifficulty(1); // set level 1 or 2 // TODO: choosable
        cardStack.setInitialCardStack(); // init Card Stack
        setCards(cardStack.objCardStack); // set the state with Objects
    }, []);

    const updateCardStack = (newStack: CardType[]) => {
        setCards(newStack);
    };
    
    /**
     * - Iterate the array to find all cards with the same position
     * - for this cards -> find die highes z-index
     * - iterate the array again to find the cards with the same position and set the updated card to a z-index++
     */
    const updateCardPositionAndZIndex = (x: number, y: number, index: number) => {
        const updatedCards = [...cards];
        
        // Update the position of the specific card
        const cardToUpdate = updatedCards.find(card => card.index === index);
        if (!cardToUpdate) {
            console.error('Card not found');
            return;
        }
    
        // Aktualisieren Sie die Position der spezifischen Karte
        cardToUpdate.cardPositionX = x;
        cardToUpdate.cardPositionY = y;
    
        // Finden Sie alle Karten an der neuen Position (außer die gerade bewegte Karte)
        const cardsAtSamePosition = updatedCards.filter(
            card => card.index !== index && card.cardPositionX === x && card.cardPositionY === y
        );
    
        // Wenn keine andere Karte an der Position ist, setzen Sie den Z-Index der bewegten Karte auf 1
        if (cardsAtSamePosition.length === 0) {
            cardToUpdate.cardZindex = 0;
        } else {
            // Finden Sie den höchsten Z-Index an der neuen Position
            const maxZIndex = cardsAtSamePosition.reduce((max, card) => Math.max(max, card.cardZindex), 0);
    
            // Setzen Sie den Z-Index der bewegten Karte auf den höchsten Z-Index + 1
            cardToUpdate.cardZindex = maxZIndex + 1;
        }
    
        // Zustand mit aktualisierten Karten setzen
        setCards(updatedCards);
    
        // Höchsten Z-Index zurückgeben (falls benötigt)
        return cardToUpdate.cardZindex;
    };


    // deckt neue karte auf
    const turnOverNextCard = () => {
        const cardsAtPosition = cards.filter(card => card.cardPositionX === 1 && card.cardPositionY === 1);
        if (cardsAtPosition.length === 0) {
            setShowBackcard(false);
            return;
        }

        const cardWithHighestZIndex = cardsAtPosition.reduce((highest, current) => {
            return current.cardZindex > highest.cardZindex ? current : highest;
        }, cardsAtPosition[0]);

        updateCardPositionAndZIndex(2, 1, cardWithHighestZIndex.index);
    }

    // karten zurücklegen
    const restackCards = () => {
        const cardsAtPosition = cards.filter(card => card.cardPositionX === 2 && card.cardPositionY === 1);

        // Berechnen Sie den neuen Z-Index für jede Karte, indem Sie die Z-Index-Werte umkehren
        const maxZIndex = cardsAtPosition.reduce((max, card) => Math.max(max, card.cardZindex), 0);
        cardsAtPosition.forEach(card => {
            card.cardZindex = maxZIndex - card.cardZindex + 1;
        });

        // Setzen Sie die Position aller Karten zurück auf 1,1
        const updatedCards = cards.map(card => {
            if (card.cardPositionX === 2 && card.cardPositionY === 1) {
                return {
                    ...card,
                    cardPositionX: 1,
                    cardPositionY: 1,
                };
            }
            return card;
        });

        // Aktualisieren Sie den Zustand mit den aktualisierten Karten
        setShowBackcard(true);
        setCards(updatedCards);
    }

    const renderBacksideCard = () => {
        if(showBackcard) {
            return (
                <Card  
                    showBackcard={showBackcard}
                    backcard={true}
                    turnOverNextCard={() => turnOverNextCard()}
                    cardStack={cards}
                    updateCardStack={updateCardStack}
                    color="blue"
                    cardData={{
                        index: 1,
                        cardValue: "#back",
                        cardZindex: -1, // TODO: switch to -1 when the stack is empty
                        cardPositionX: 1,
                        cardPositionY: 1,
                        cardHidden: false
                    }}
                />
            )
        } else {
            // TODO: Button für Karten zurück stappeln
            return  (
                <button className="restackCards" onClick={ ()=>restackCards() }>Stapel zurücksetzen</button>
            )
        }
    }


    const renderCards = () => {
        return (
             cards.map((card) => (
                <Card  
                    backcard={false}
                    key={card.index}
                    cardData={card}
                    cardStack={cards}
                    updateCardStack={updateCardStack}
                    updateCardPositionAndZIndex={(x,y) => updateCardPositionAndZIndex(x,y,card.index)}
                />
            ))
        )
    }


    return (
        <>
            {/*<button onClick={ ()=>printAllCards(cards) }>print all cards</button>*/}
            <div className="deckBody" data-testid="deck-1">
                { renderPlaceholder() }
                { renderCards() }
                { renderBacksideCard() }
            </div>
            {/* { renderDataFirstCard(cards) } */}
        </>
    );
}