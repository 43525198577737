import { CardType } from './cardType';

export const checkPosition = (rowY: number, columnX: number, cardData: CardType, cardStack: CardType[], multipleDndCards: CardType[]) => {

    // cardStack an der selben Position
    const cardsAtPosition: CardType[] = cardStack.filter(card => card.cardPositionY === rowY && card.cardPositionX === columnX);
    
    // Gewählte Karte
    const cardDetails: CardDetails = parseCardString(cardData.cardValue)
    
    /**
     * - nur oben die 4. Slots (Foundation)
     * - vergleicht die gewählte karte mit allen karten die bereits an der neuen position liegen
     * - prüft das nicht mehrere karten versucht werden abzulegen
    */
   if ((rowY === 1 && columnX > 3) && !multipleDndCards.length) { 
       // Überprüfen Sie, ob cardStack leer ist und es ein Ass ist
       if (cardsAtPosition.length === 0 && cardDetails.value === 1) {
            return true;
        }

        for (let i = 0; i < cardsAtPosition.length; i++) {
            let cardStackDetail = parseCardString(cardsAtPosition[i].cardValue);
            if(canPlaceCardOnTopFoundation(cardStackDetail, cardDetails)) {
                return true;
            }
        }
    }

    /**
     * - nur unten die Stapel Slots (Tableau)
     * - vergleicht die gewählte karte mit allen karten die bereits an der neuen position liegen
    */
   if (rowY === 2) {

        // leere Psoition und es muss ein König sein
        if (cardsAtPosition.length === 0 ) {
            if (cardDetails.value === 13) {
                return true;
            }
            else {
                return false;
            }
        }

        const cardWithHighestZindex = cardsAtPosition.reduce((highestCard, currentCard) => {
            return currentCard.cardZindex > highestCard.cardZindex ? currentCard : highestCard;
        }, cardsAtPosition[0]);  // Startwert ist das erste Element
        
        let cardStackDetail = parseCardString(cardWithHighestZindex.cardValue); // translate { suit: "club", value: 7, color: "black" }
        if (canPlaceCardOnTopTableau(rowY, columnX, cardData, cardStackDetail, cardDetails) ) {
            return true;
        }
    }

    else {
        return false;
    }

}

type CardDetails = {
    suit: string;
    value: number;
    color: string;
}

// gibt Obj zurück e.g { suit: 'spade', value: 1, color: 'black' }
export const parseCardString = (cardString: string): CardDetails => {
    // Entfernen des '#' am Anfang des Strings und Aufteilen an '_'
    const parts = cardString.slice(1).split('_');

    const suit = parts[0];
    let rawValue = parts[1];
    let value: number;

    switch(rawValue) {
        case 'jack':
            value = 11;
            break;
        case 'queen':
            value = 12;
            break;
        case 'king':
            value = 13;
            break;
        default:
            value = parseInt(rawValue, 10);
            if (isNaN(value)) {
                throw new Error('Unerwarteter Kartenwert: ' + rawValue);
            }
            break;
    }

    let color: string;
    switch(suit) {
        case 'diamond':
        case 'heart':
            color = 'red';
            break;
        case 'club':
        case 'spade':
            color = 'black';
            break;
        default:
            throw new Error('Unbekanntes Kartensymbol: ' + suit);
    }

    return {
        suit,
        value,
        color
    };
}



export const canPlaceCardOnTopFoundation = (
    topCard: CardDetails, 
    cardToPlace: CardDetails,
  ): boolean => {
    // Wenn die Karten unterschiedliche Symbole haben, kann die Karte nicht platziert werden.

    if (topCard.suit !== cardToPlace.suit) {
      return false;
    }
  
    // Wenn die Karte, die Sie ablegen möchten, genau einen Wert über der obersten Karte liegt.
    return cardToPlace.value === topCard.value + 1;
  }
  
  export const canPlaceCardOnTopTableau = (
    rowY: number, 
    columnX: number,
    cardData: CardType,
    cardStackDetail: CardDetails, 
    cardDetails: CardDetails
  ): boolean => {
    // Wenn die Karten gleiche farben haben, kann die Karte nicht platziert werden.
    if (cardStackDetail.color === cardDetails.color) {
      return false;
    }

    // Karte darf nicht aus dem Tablou beliebig gezogen und an die selbe stelle wieder abgelegt werden
    if ((columnX === cardData.cardPositionX) && (rowY === cardData.cardPositionY) ) {
        return false;
    }
  
    // Wenn die Karte, die Sie ablegen möchten, genau einen Wert darunter der obersten Karte liegt.
    return cardDetails.value === cardStackDetail.value - 1;
  }