export const renderDataFirstCard = (cards) => {
    if (!cards[51]) return null;

    return (
        <>
            <p>First Card:</p>
            <p>Value: {cards[51].cardValue}</p>
            <p>Index: {cards[51].index}</p>
            <p>Z-Index: {cards[51].cardZindex}</p>
            <p>Y: {cards[51].cardPositionY}</p>
            <p>X: {cards[51].cardPositionX}</p>
        </>
    );
}

export const renderPlaceholder = () => {
    return (
        <>
            <div className='stack_sign stack_1'></div>
            <div className='stack_sign stack_2'></div>
            <div className='stack_sign stack_3'></div>
            <div className='stack_sign stack_4'></div>
            <div className='stack_sign stack_5'></div>
            <div className='stack_sign stack_6'></div>
            <div className='stack_sign stack_7'></div>

            <div className='stack_sign deck_pos_1'></div>
            <div className='stack_sign deck_pos_2'></div>
            <div className='stack_sign deck_pos_3'></div>
            <div className='stack_sign deck_pos_4'></div>
            <div className='stack_sign deck_pos_5'></div>
            <div className='stack_sign deck_pos_6'></div>
            <div className='stack_sign deck_pos_7'></div>
        </>
    )
}

export const printAllCards = (cards) => console.warn('alle cards - after useEffect',cards);