import * as data from './../data/deckOne.json';
import { CardType } from './cardType';
let _ = require('underscore');

/**
 * Forcec to generate only one time per loading the Page a set of Cards
 * TODO: renome to cardArr
 */export class CardStack {
    arrSortedOrder: Array<number>;
    arrShufflOrder: Array<number>;
    arrAcesReorder: Array<number>;
    objCardStack: CardType[];
    private posibleAcesPositionsInTablou: Array<number> = [0,1,2,4,5,8,9,13,14,19,20,26,27];
    private posibleAcesPositionsInStack: number[] = Array.from({length: 24}, (_, i) => i + 27); // [27, 28, ..., 50]
    private validAcesPositions: Array<number> = [0,0,0,0];

    public setInitialCardStack = () => {
        this.arrSortedOrder = this.createCardArray(data);
        this.arrShufflOrder = _.shuffle([...this.arrSortedOrder]); // Eine Kopie erstellen, um das Original nicht zu beeinflussen

        /**
         * Umsortieren der Asse für ein ausgeglichenes spiel:
         * - Möglichke Positionen: 0,1,2,4,5,8,9,13,14,19,20,26,27 im Tablou
         * - ansonsten alles nach 27 im Stack
         * - 12,25,38 und 51 sind die fixen indexe von den Assen
         */
        this.arrShufflOrder.forEach((value: number, index: number) => {
            
            switch(value) {
                case 12:
                    this.switchStackPosition(index);
                    break;
                case 25:
                    this.switchStackPosition(index);
                    break;
                case 38:
                    this.switchStackPosition(index);
                    break;
                case 51:
                    this.switchStackPosition(index);
                    break;
                default:
                    break;
            }
        });

        this.objCardStack = this.arrAcesReorder.map((value: number, index: number) => {
            let cardPositionX, cardPositionY, hidden, cardZindex;
        
            if (index < 28) {
                const column = Math.floor((1 + Math.sqrt(1 + 8 * index)) / 2);
                const cardsInPreviousColumns = column * (column - 1) / 2;
        
                // Karten innerhalb der gleichen Spalte sollten mit 0 beginnen und dann aufsteigen
                cardZindex = index - cardsInPreviousColumns;
        
                cardPositionX = column;
                cardPositionY = 2;
        
                hidden = index !== cardsInPreviousColumns + column - 1;
            } else {
                cardPositionX = 1;
                cardPositionY = 1;
                hidden = false;
                cardZindex = index - 28;
            }
        
            return {
                index: index,
                cardValue: this.setCardValue(value),
                cardZindex: cardZindex,
                cardPositionX: cardPositionX,
                cardPositionY: cardPositionY,
                cardHidden: hidden
            };
        });        
    }


    public setLevelOfDifficulty = (level: number) => {
        this.setValidAcesPosition(level);
    }

    private setValidAcesPosition = (level: number) => {

        // sets 3 Cards in Tablou and 1 in Stack
        if (level === 1) {

            // Tablou
            for (let i = 0; i < 3; i++) {
                let randomIndex1 = Math.floor(Math.random() * this.posibleAcesPositionsInTablou.length);
                this.validAcesPositions[i] = this.posibleAcesPositionsInTablou.splice(randomIndex1, 1)[0];
            }
            
            // Stack
            let randomIndex2 = Math.floor(Math.random() * this.posibleAcesPositionsInStack.length);
            this.validAcesPositions[3] = this.posibleAcesPositionsInStack.splice(randomIndex2, 1)[0];
        }

        // sets 2 Cards in Tablou and 2 in Stack
        else if (level === 2) {

            // Tablou
            for (let i = 0; i < 2; i++) {
                let randomIndex1 = Math.floor(Math.random() * this.posibleAcesPositionsInTablou.length);
                this.validAcesPositions[i] = this.posibleAcesPositionsInTablou.splice(randomIndex1, 1)[0];
            }
            
            // Stack
            for (let i = 2; i < 4; i++) {
                let randomIndex2 = Math.floor(Math.random() * this.posibleAcesPositionsInStack.length);
                this.validAcesPositions[i] = this.posibleAcesPositionsInStack.splice(randomIndex2, 1)[0];
            }
            
        }
    }
    
    private createCardArray = (amount) => {
        let cards = [];
        for (let index = 0; index < amount.length; index++) {
            cards.push(index);
        }
        return cards;
    }

    private setCardValue = (cardNumber: number) => {
        if (typeof data[cardNumber] === "undefined") {
            console.error("Fehler: Ungültiger Kartenindex. Es wurden alle Karten aufgebraucht.");
            return ''; 
        }

        let removeChar = (x) => x.replace(/"/g,"");
        
        let deckSuitStr = removeChar(JSON.stringify(data[cardNumber].suit));
        let deckValueStr = removeChar(JSON.stringify(data[cardNumber].value));

        return deckSuitStr + deckValueStr; 
    }

    private switchStackPosition = (currentAcePosition: number) => {

        if (this.validAcesPositions.length === 0) {
            // console.warn("Keine validen Positionen mehr vorhanden!");
            return; // Frühzeitig zurückkehren, wenn keine Positionen mehr verfügbar sind
        }

        this.arrAcesReorder = this.arrShufflOrder;
        // console.log('Array: ', this.arrAcesReorder);
    
        const firstValidAce = this.validAcesPositions.shift();
        
        const toMovedCard = this.arrAcesReorder[firstValidAce]; // Karte
        const toMovedAce = this.arrAcesReorder[currentAcePosition]; // Ass
        
        this.arrAcesReorder[firstValidAce] = toMovedAce;
        this.arrAcesReorder[currentAcePosition] = toMovedCard;
        
        // console.log('Array 2: ', this.arrAcesReorder);
    }
    
}
