import {useEffect, useState} from 'react';
import "./card.scss";
import Img1 from './svgCards/svg-cards.svg';
// import { UseMouse } from '../../hooks/MousePositionHook';
import { CardType } from '../../utils/cardType';
import { checkPosition } from '../../utils/gameLogic';

interface CardProps {
    showBackcard?: boolean;
    backcard: boolean;
    cardStack?: CardType[]; // alle card data
    color?: string;
    turnOverNextCard?: (() => void);
    cardData: CardType; // one selected data
    updateCardPositionAndZIndex?: (x: number, y: number) => void;
    updateCardStack: (newStack: CardType[]) => void;
}

export const Card = (props: CardProps) => {

    const [press, setPress] = useState(1);
    const [cardBodyCss, setCardBodyCss] = useState("cardBody_0");
    const [bg,setBg] = useState("");
    const [shouldFlip,setShouldFlip] = useState(false);
    const [oldPosition, setOldPosition] = useState<number[]>([]);
    const [multipleDndCards, setMultipleDndCards] = useState<CardType[]>([]);
    const [pressBoolean,setPreessBoolean] = useState(false);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleMouseMove = (event: MouseEvent) => {
            if (pressBoolean) {
                setMousePosition({ x: event.clientX, y: event.clientY });
            }
        };
        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [pressBoolean]);


    const handleButtonDown = (event: React.MouseEvent<HTMLDivElement>) => {
        if(!props.backcard) {
            setPress(press + 1);
            setCardBodyCss("cardBody_1")
            setPreessBoolean(true);
            setMousePosition({ x: event.clientX, y: event.clientY }); // init mouseposition
            setOldPosition([props.cardData.cardPositionY, props.cardData.cardPositionX]);
            findMultipleDndCards(props.cardData.cardPositionY, props.cardData.cardPositionX, props.cardStack);
        }
    }

    const handleButtonUp = () => {
        setCardBodyCss("cardBody_0")
        setPreessBoolean(false);
        switchPosition();
    }

    const handleCardOnMouse = (rowY, columnX, fanning, id) => {

        /**
         * - Game logic
         * - update cardStack
         * - return true if new positioning is allowed
         **/
        if (checkPosition(rowY, columnX, props.cardData, props.cardStack, multipleDndCards)) {
            props.updateCardPositionAndZIndex(columnX, rowY);

            // Speichern Sie die aktuelle Position der Karte
            const oldRowY = oldPosition[0];
            const oldColumnX = oldPosition[1];

            // Überprüfe und decke die oberste versteckte Karte an der neuen Position auf
            revealTopHiddenCard(oldRowY, oldColumnX, props.cardStack);

            // update card Stack with multipleDnd Cards
            if(multipleDndCards.length) {
                updateStackWithMultipleDndCards(oldRowY, oldColumnX);
            }
        }
    }

    const switchPosition = () => {

        const { x, y } = mousePosition;

        const row: number = 225; // over or under this y switches between
        const column: number = 155; // start value from x
        const calcColumn: number = 146; // add value for the next card on x

       // 0. Start Stack
        if (x < column && y < row) {
            handleCardOnMouse(1, 1, false, 1);
        }
        // 1.
        else if ((x > column && x < (column + calcColumn * 1)) && y < row) {
            handleCardOnMouse(1, 2, false, 2);
        }
        // 2.
        else if ((x > (column + calcColumn * 1) && x < (column + calcColumn * 2)) && y < row) {
            handleCardOnMouse(1, 3, false, 3);
        }
        // 3.
        else if ((x > (column + calcColumn * 2) && x < (column + calcColumn * 3)) && y < row) {
            handleCardOnMouse(1, 4, false, 4);
        }
        // 4.
        else if ((x > (column + calcColumn * 3) && x < (column + calcColumn * 4)) && y < row) {
            handleCardOnMouse(1, 5, false, 5);
        }
        // 5.
        else if ((x > (column + calcColumn * 4) && x < (column + calcColumn * 5)) && y < row) {
            handleCardOnMouse(1, 6, false, 6);
        }
        // 6.
        else if ((x > (column + calcColumn * 5) && x < (column + calcColumn * 6)) && y < row) {
            handleCardOnMouse(1, 7, false, 7);
        }

        // 0. Game Stack
        else if (x < column && y > row) {
            handleCardOnMouse(2, 1, true, 8);
        }
        // 1.
        else if ((x > column && x < (column + calcColumn * 1)) && y > row) {
            handleCardOnMouse(2, 2, true, 9);
        }
        // 2.
        else if ((x > (column + calcColumn * 1) && x < (column + calcColumn * 2)) && y > row) {
            handleCardOnMouse(2, 3, true, 10);
        }
        // 3.
        else if ((x > (column + calcColumn * 2) && x < (column + calcColumn * 3)) && y > row) {
            handleCardOnMouse(2, 4, true, 11);
        }
        // 4.
        else if ((x > (column + calcColumn * 3) && x < (column + calcColumn * 4)) && y > row) {
            handleCardOnMouse(2, 5, true, 12);
        }
        // 5.
        else if ((x > (column + calcColumn * 4) && x < (column + calcColumn * 5)) && y > row) {
            handleCardOnMouse(2, 6, true, 13);
        }
        // 6.
        else if ((x > (column + calcColumn * 5) && x < (column + calcColumn * 6)) && y > row) {
            handleCardOnMouse(2, 7, true, 14);
        }
    }

    const handleBackcard = () => {
        props.turnOverNextCard();
    }

    // deckt eine Karte auf wenn an der Pos alle verdeckt sind
    const revealTopHiddenCard = (oldRowY: number, oldColumnX: number, cardStack: CardType[]) => {
        // Filtere Karten an der angegebenen Position
        const updatedStack = [...cardStack];
        const cardsAtPosition = updatedStack.filter(card => card.cardPositionY === oldRowY && card.cardPositionX === oldColumnX);

        // Sortiere die Karten basierend auf dem zIndex
        cardsAtPosition.sort((a, b) => a.cardZindex - b.cardZindex);

        // Prüfe, ob alle Karten hidden sind
        const allCardsHidden = cardsAtPosition.every(card => card.cardHidden);

        /**
         * TODO:
         * hier muss der karte welche cardHidden false gegeben wird ein zusätzliches prop gegebn werden
         * das prop löscht sich mit dem useEffect nach 2000ms
         * karten mit dem prop müssen animiert werden
         */

        if (allCardsHidden && cardsAtPosition.length > 0) {
            updatedStack[findIndexOfCard(cardsAtPosition[cardsAtPosition.length - 1], updatedStack)].cardHidden = false;
            props.updateCardStack(updatedStack);  // Hier informieren wir die übergeordnete Komponente über die Änderung.
            setShouldFlip(true);
        }
    };

    /**
     * - suche auf der oldPosition[y,x] alle karten
     * - schaue nach der karte mit dem höchsten zIndex
     * - if (höchster zIndex != props.cardData.zIndex)
     *  - filtere die Karten heraus die einen größeren zIndex haben als props.cardData
     *  - speichere die Karten in einem state setMultipleDndCards()
     **/
    const findMultipleDndCards = (oldRowY: number, oldColumnX: number, cardStack: CardType[]) => {
            // Filtere Karten an der angegebenen Position
            const updatedStack = [...cardStack];
            let cardsAtPosition = updatedStack.filter(card => card.cardPositionY === oldRowY && card.cardPositionX === oldColumnX);

            const highestZindex = Math.max(...cardsAtPosition.map( card => card.cardZindex));

            if (highestZindex !== props.cardData.cardZindex) {
                setMultipleDndCards(cardsAtPosition.filter( card => card.cardZindex > props.cardData.cardZindex ));
            }
    }

    /**
     * Wird ausgelöst wenn mehrere Karten verschoben werden können
     * - An der neues Position wird geprüft welche Karten dort bereits liegen
     * - Der zIndex aller Karten wird neu vergeben und geupdatet
     */
    const updateStackWithMultipleDndCards = (oldRowY: number, oldColumnX: number) => {
        const updatedStack = [...props.cardStack];

        let zIncrease: number = props.cardData.cardZindex +1;

        // Aktualisiert updatedStack mit multipleDndCards basierend auf dem Index
        multipleDndCards
            .sort((a, b) => a.cardZindex - b.cardZindex) //  sortiert die Karten in aufsteigender Reihenfolge ihres cardZindex
            .forEach(card => {
                const index = updatedStack.findIndex(stackCard => stackCard.index === card.index);
                if(index !== -1) {

                    card.cardPositionX = props.cardData.cardPositionX;
                    card.cardPositionY = props.cardData.cardPositionY;
                    card.cardZindex = zIncrease;
                    updatedStack[index] = card;
                }
                zIncrease++; // increase z-index for the next card
            });

        props.updateCardStack(updatedStack); // new stack for update und rerender trigger
        revealTopHiddenCard(oldRowY, oldColumnX, props.cardStack); // checken ob Karten an letzter pos verdeckt sind
        setMultipleDndCards([]); // leert den state wieder
    }

    // Helper function, um den Index einer Karte im Stapel zu finden.
    const findIndexOfCard = (card: CardType, stack: CardType[]): number => {
        return stack.findIndex((c) => c.index === card.index);
    };


    // scale
    let scale_num: number = 0.6;
    let svgWidth: number = 170 * scale_num;
    let svgHeight: number = 250 * scale_num;

    let scale_css: string = scale_num.toString();
    scale_css = "scale(" + scale_css + ")";
    let card_css: string = svgWidth.toString();
    card_css = card_css + 'px';

    let zIndexRank: number;
    if (props.cardData.cardPositionY === 2) {
            zIndexRank = props.cardData.cardZindex;
    } else {
        zIndexRank = 0;
    }

    // Berechnen Sie den Versatz basierend auf dem zIndexRank.
    let fanning: number = 25 * zIndexRank;

    // Verwandeln Sie den Versatz in einen String für die transform-Eigenschaft.
    let fanningString: string = `translateY(${fanning}px)`;

    // Card behavior in css - grid
    const setStyle = {
        default: {
            backgroundColor: bg,
            width: card_css,
            zIndex: props.backcard ? 9999 : props.cardData.cardZindex,
            transform: fanningString
        },
        mouseMove: pressBoolean ? {
            top: mousePosition.y-((svgHeight/2)+fanning),
            left: mousePosition.x-(svgWidth/2)
        } : {},
        gridPosition: !pressBoolean ? {
            gridRowStart: props.cardData.cardPositionY,
            gridRowEnd: props.cardData.cardPositionY,
            gridColumnStart: props.cardData.cardPositionX,
            gridColumnEnd: props.cardData.cardPositionX,
        } : {}
    };



    const composedStyle = Object.assign({}, setStyle.default ,setStyle.mouseMove , setStyle.gridPosition);

    if(props.backcard) {
        return (
            <div
                className={"cardBody_default "+cardBodyCss}
                data-testid={`backcard-${props.cardData.index}`}
                style=  { composedStyle }
                onClick={ handleBackcard }
                onMouseOver={ () => {setBg('yellow')} }
                onMouseOut={ () => {setBg('')} }
            >
                <svg width={svgWidth} height={svgHeight}>
                    <use href={Img1 + props.cardData.cardValue} x="0" y="0" fill={props.color} transform={scale_css}/>
                </svg>
            </div>
        );
    }else{
        return (
                <div
                    className={"cardBody_default " + cardBodyCss + (shouldFlip ? " flipped" : "")}
                    data-testid={`card-${props.cardData.index}`}
                    style=  { composedStyle }
                    onMouseUp={ !props.cardData.cardHidden ? handleButtonUp : null}
                    onMouseDown={ !props.cardData.cardHidden ? handleButtonDown  : null }
                    onMouseOver={ !props.cardData.cardHidden ? () => {setBg('yellow')} : null }
                    onMouseOut={ !props.cardData.cardHidden ? () => {setBg('')} : null }
                >
                    <svg width={svgWidth} height={svgHeight}>
                        <use href={Img1 + (props.cardData.cardHidden ? '#back' :props.cardData.cardValue)} x="0" y="0" fill={props.cardData.cardHidden ? 'blue' : props.color} transform={scale_css}/>
                    </svg>
                </div>
        );
    }
}



