import './app.scss';
import { Deck } from "./component/deck/Deck";

export const App = () => {
    return (
        <div className="app" style={{ backgroundImage: "url(/./img/texture_1.jpg)" }}>
            <Deck />
        </div>
    );
}

